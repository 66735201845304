import React from "react";
import {  CardHeader } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

const PermissionDenied = () => {

    return (
        <>
           <CardHeader
			style={{ cursor: "pointer" }}
			titleTypographyProps={{ noWrap: true }}
			subheaderTypographyProps={{ noWrap: true }}
			title="Permissão de Acesso"
			content="conteudo da mensagem" 
			subheader={
                "Permissão negada ao Ticket! " && "Entre em contato com o Administrador do sistema. "
				// `${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`
			}
		/>
        </>
    )

}
export default PermissionDenied; 